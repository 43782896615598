@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

$search-input-border: 2px;

.search-input {
  border: 2px solid;
  border-radius: 59px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;

  @include themify($themes) {
    color: themed('textPrimary');
    background: themed('backgroundSecondary');
    border-color: themed('accentPrimarySurf')
  }

  .homepage & {
    max-width: 600px;
    position: relative;
    margin: 0 auto;

    &:hover {
      box-shadow: 0 2px 6px rgba(18, 25, 63, 0.12), 0 4px 12px rgba(18, 25, 63, 0.08), 0 8px 32px rgba(18, 25, 63, 0.08);
    }
  }

  .input {
    height: 100%;
    /* Preserving enough space for the
     * "Close" (x) icon.
     */
    padding-right: 98px;
    text-overflow: ellipsis;
    border-radius: 59px;

    &:placeholder-shown {
      text-overflow: ellipsis;
      /* More space for the placeholder if
       * nothing was typed in. "Close" (x)
       * not visible in this case.
       */
      padding-right: $spacing * 11;
    }
  }

  input[value=""] + .search-input__clear {
    display: none;
  }

  &--open {
    border-radius: 23px;
    margin-bottom: 2px;

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      border-radius: 23px;
    }

    .input {
      border-radius: 21px 21px 0 0;
      padding-right: 92px;
      padding-left: 50px;

      .search-input--mobile-active & {
        padding-left: 16px;
      }
    }

    .search-input {
      &__button {
        .search-input--mobile-active & {
          display: none;
        }
      }
    }

    &:hover {
      .homepage & {
        box-shadow: 0 2px 6px rgba(18, 25, 63, 0.12), 0 4px 12px rgba(18, 25, 63, 0.08), 0 8px 32px rgba(18, 25, 63, 0.08);
      }
    }
  }

  &__form {
    position: relative;
  }

  &__search-icon {
    color: $white;
    padding-left: 1px;
    
    .dark-theme & path {
      fill: #000;
    }
  }

  &__left-icon {
    display: none;
    position: absolute;
    left: $spacing * 4;
    top: $spacing * 4;

    .homepage & {
      top: 16px;
    }

    .search-input--open & {
      display: inline-block;
    }

    .fixed & {
      top: 6px;
    }
  }

  &__left-icon-svg {
    width: 16px;

    @include themify($themes) {
      color: themed('nortonIconPrimary');
    }
  }

  &__button {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: none;
    outline: none;
    background: #00CEF5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    z-index: 1;
  }

  &__clear {
    position: absolute;
    top: 11px;
    display: flex;
    right: 62px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;

    /**
     * This selector isn't a beauty because it has
     * historically grown. It needs refactoring.
     */
    .search-input.search-input--mobile-active & {
        right: 15px;
    }
  }

  &__clear-icon {
    transition: all 0.25s ease-out;

    @include themify($themes) {
      color: themed('iconPrimary');
    }
  }

  &__clear-border {
    height: 100%;
    position: absolute;
    right: -9px;

    top: 0;

    .fixed & {
      top: 1px;
      right: -17px;
    }

    @include themify($themes) {
      border-right: 1px solid themed('strokePrimary');
    }

    .homepage & {
      @include themify($themes) {
        border-right: 1px solid themed('homeOmniboxStroke');
      }
    }
  }

  &__suggestions-list {
    z-index: 1;
    padding: 0;
    font-size: 18px;
    line-height: 22px;
    overflow: hidden;
    transition: all 0.25s ease-out;
    position: relative;
    width: 100%;
    border: none;
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
    margin: 0;

    @include themify($themes) {
      color: themed('textPrimary');
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: $spacing * 4;
      right: $spacing * 4;
      height: 1px;

      @include themify($themes) {
        background: themed('homeOmniboxStroke');
      }
    }
  }

  &__suggestions-item {
    list-style: none;
    text-align: left;
    padding: 0 6px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:first-child {
      padding-top: 6px;
    }

    &:last-child {
      padding-bottom: 6px;
    }

    &--bold {
      font-weight: bold;
    }

    &--active, &:hover {
      .search-input {
        &__suggestions-item-inner {
          @include themify($themes) {
            background: themed('backgroundSecondaryHover');
          }

          .homepage & {
            @include themify($themes) {
              background: themed('homeOmniboxSuggestionHover');
            }
          }
        }
      }
    }

    &:active {
      .search-input {
        &__suggestions-item-inner {
          @include themify($themes) {
            background: themed('backgroundSecondaryPressed');
          }

          .homepage & {
            @include themify($themes) {
              background: themed('homeOmniboxSuggestionPressed');
            }
          }
        }
      }
    }
  }

  &__suggestions-item-inner {
    padding: 6px 8px 6px 44px;
    position: relative;
    font-size: 16px;
    font-family: $font-family-secondary;
    border-radius: 36px;
  }

  &__suggestions-item-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    z-index: 1;

    &--search {
      left: 10px;

      .search-input--mobile-active & {
        left: 6px;
      }
    }

    &--arrow {
      right: 6px;
    }
  }

  &__suggestions-search-icon {
    width: 16px;
    height: 16px;

    @include themify($themes) {
      color: themed('iconPrimary');
    }
  }

  &__suggestions-arrow-icon {
    @include themify($themes) {
      color: themed('iconPrimary');
    }
  }

  &__back-icon {
    @include themify($themes) {
      color: themed('nortonIconPrimary');
    }
  }
}

.search-input--mobile-active.search-input {
  margin: 0;
  transition: none;
  box-shadow: none;
  border: none;

  &:focus,
  &:hover,
  &:active,
  &--force-active,
  &--open {
    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      width: 100%;
      height: 100vh;
      z-index: $z-index-fullscreen-settings;
      border-radius: 0;
      overflow: auto;

      @include themify($themes) {
        background: themed('backgroundSecondary');
      }
    }

    .input {
      padding: 11px 40px 11px 46px;
      border-radius: 0;
    }

    .search-input {
      &__button {
        &--back {
          top: 2px;
          width: 36px;
        }
      }

      &__clear-border {
        display: none;
      }

      &__suggestions-list {
        border-radius: 0;
      }
    }
  }

  .input {
    padding: 11px 20px 11px 42px;
    height: initial;
    font-size: 16px;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  .search-input {
    &__button {
      &--back {
        top: 6px;
        right: auto;
        left: 5px;
        cursor: pointer;
        padding: 3px 6px 6px;
        background: none;
      }

      &--search {
        display: none;
      }
    }

    &__suggestions-item-inner {
      padding-left: $spacing * 10;
      padding-right: 34px;
    }
  }
}