@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("../../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Light";
  src: url("../../fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins Thin";
  src: url("../../fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

/**
 * ## Logo
 */
 .company-logo-wrapper {
  text-align: center;
  width: 100%;
  align-self: flex-end;

  /* - Logo inside company pages's header: */
  .navbar-sticky__logowrapper & {
    text-align: left;
    width: 176px;
  }

  /* - Logo inside search result page's header: */
  .header__main & {
    height: auto;
    margin: ($spacing * 4) 0 ($spacing * 3) 0;
    width: 120px;

    @include minSm {
      margin: 8px 0 0;
      width: 115px;
    }

    @include maxSm {
      margin: 0;
    }
  }

  /* - Logo inside search result page's FIXED header: */
  .header.fixed & {
    height: auto;
    margin-left: -4px;
    width: 115px;
  }
}

.logo-svg {
  height: auto;
  width: 100%;

  /* - Logo on Homepage above search input: */
  .fixed-half & {
    margin-bottom: 29px;
    width: auto;
    margin: 20px auto 35px;
    transform: scale(1.5);
    height: 33px;
    width: 130px;

    @include minSm {
      height: auto;
      height: 35px;
    }

    @include minSm {
      transform: scale(2);
      margin: 10px auto 45px;
    }
  }

  .dark-theme &,
  &--bright {
    .path-typography {
      fill: $white;
    }
  }
}

/**
 * This class on the `<html>` tag is set via JavaScript.
 * It serves the purpose of faking a visual prolonging
 * of the `PageFooter` component's dark background.
 */
.interior-page {
  @include themify($themes) {
    background: 'backgroundSecondary';
  }
}

.homepage {
  width: 100%;
  height: 100%;
  font-family: "Poppins", Helvetica, Arial, sans-serif;

  &__filler {
    height: 100%;
  }

  /**
   * Tempest pride. 🏳️‍🌈
   */
  .tempest-wrapper-pride {
    display: inline-block;
    position: relative;
  }

  .tempest-logo-pride {
    display: block;
    height: auto;
    margin-bottom: 29px;
    width: 273px;
    transform: scale(.838);

    @include minSm {
      height: auto;
      transform: scale(1);
      width: 273px;
    }
  }

  &__slide-teaser {
    background: linear-gradient(51.14deg, #6081F5 -11.73%, #5341C5 57.09%, #3D2F99 102.08%);
    overflow: hidden;
    padding: $border-radius-xxxxl 0 0;

    @include minSm {
        padding-left: $spacing * 12;
        padding-right: $spacing * 12;
    }

    .slide-container__list {
      @include minSm {
        margin: 46px 0 0 38px;
      }
    }
  }
}

.fixed-half {
  left: 0;
  top: 0;
  width: 100%;
  min-height: 70vh;
  /* -60px to substract the height of `.navbar` : */
  height: 100%;
  z-index: 1;

  @include minSm {
    min-height: 100vh;
    position: fixed;
  }

  /* - Keeping the menu and suggestions working when they're used. */
  .menu-open & {
    z-index: 2;
  }

  /**
   * This class `fixed-half--scrolled` is set via JavaScript on scroll.
   * It serves the purpose of faking a visual prolonging
   * of the `PageFooter` component's dark background.
   */
  &.fixed-half--scrolled {
    background-color: $marketing-blue-black-500;
  }

  &__body {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    justify-items: center;
    height: 100%;
    position: relative;

    &__wrapper {
      width: 100%;
      padding: 0 ($spacing * 5);
      position: absolute;
      // 221px - is 70px of menu height + 141px total height of main Logo and Search form height
      top: calc(50% - 211px);
    }
  }
}

.scrolling-half {
  background-color: transparent;
  position: relative;
  z-index: 2;

  @include minSm {
    margin-top: calc(100vh - 74px);
  }

  /* - Keeping the menu and suggestions completely visible, meaning
   * not cut off, even on mobile devices:
   */
  .search-suggestions-open & {
    @include maxSm {
      z-index: 0;
    }

    @media (max-width: #{$screen-max-sm}) or (max-height: 780px) {
      z-index: 0;
    }
  }
}

/**
 * - Menu wrapper outside
 * a helping container outside of `.fixed-half`/`.scrolling-half` to
 * mimic the menu's position while keeping it out of trouble
 * with overlaps caused by these containers.
 */
.menu-wrapper-outside {
  position: fixed;
  top: 0;
  height: 0;
  width: 100%;
  z-index: $z-index-header-settings;

  @media (min-width: 700px) {
    max-width: 1220px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
  }

  .menu__button {
    display: none;
  }

  .menu__body {
    top: 0;
    overflow: hidden;
    overflow-y: auto;

    @media (min-width: 700px) {
      top: ($spacing * 7);
    }
  }
}

/**
 * - This class displays a background layer to cover the rest
 * of the page when the menu is open.
 */
@mixin menu-background-layer($z-index: $z-index-header-default - 1) {
  &::before {
    @media (max-width: 699px) {
      content: "";
      height: 100vh;
      left: 0;
      width: 100vw;
      position: fixed;
      top: 0;
      z-index: $z-index-header-default - 1;

      @include themify($themes) {
        background: themed('transparentLayer');
      }
    }
  }
}


.menu-open .homepage,
.sticky-menu-open .simple-header__menu {
  @include menu-background-layer;
}

.menu-open .header__inner {
  @include menu-background-layer($z-index: 2);
}

.home-centerwrapper,
.home-centerwrapper-wide,
.home-centerwrapper-narrow {
  margin: 0 auto;
  padding: 0 ($spacing * 6);

  @include minMd {
    padding-left: $spacing * 13;
    padding-right: $spacing * 13;
    padding-bottom: $spacing * 9;
  }
}

.home-centerwrapper {
  max-width: 772px;
}

.home-centerwrapper-wide {
  max-width: $screen-max-md-aside + ($spacing * 13);

  .teaser-headline-decoration--search-page &,
  .teaser-benefits & {
    padding-right: 0;
    max-width: 1440px;

    & .row__left {
        @media (min-width: 769px) {
            width: 45%;
        }
    }

    & .row__right {
        @media (min-width: 769px) {
            width: 55%;
        }
    }
  }
}

.home-centerwrapper-narrow {
  max-width: 900px;

  &--mobile-color-swap {
    @include maxSm {
      background-color: $marketing-blue-500;
    }
  }

  &--line-merge {
    @include maxSm {
      margin-top: -300px;
      padding-top: 250px;
    }
  }
}

/**
 * ### Privacy Policy
 */
 .home-legal-page {
  color: rgba($white, .6);
  overflow: hidden;
}

/**
 * ## Typography
 */
.home-text-centered {
  margin-bottom: $spacing * 10;
  text-align: center;

  &--divider-top {
    @include minMd {
      position: relative;
      margin-top: $spacing * 20;
      padding-top: $spacing * 10;
    }

    &::before {
      @include minMd {
        background-color: rgba($white, .1);
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        max-width: 590px;
        top: 0;
        width: calc(100% - ($spacing * 8));
        @include center-x(absolute);
      }
    }
  }
}

.homepage-headline {
  font-family: 'Poppins Light';
  font-size: 32px;
  line-height: 48px;
  padding-bottom: $spacing * 1;

  @include themify($themes) {
      color: themed('textPrimary');
  }

  @include minSm {
      font-size: 44px;
      line-height: 58px;
  }

  &__separator {
      border-bottom: 1px solid rgba($white, .1);
  }
}

.homepage-headline-big {
  @extend %homepage-teaser-subheadline;

  .teaser-headline-image & {
    margin: ($spacing * 20) auto ($spacing * 4);
    max-width: 600px;
    text-align: center;
  }

  .about & {
    margin: 50px 0 30px;

    @include minSm {
        margin: 90px 0 50px;
    }
  }

  .pagefooter & {
    margin: 14px 0 ($spacing * 7);
  }

  &--no-change {
    font-size: 44px;
    line-height: 62px;
  }
}

.homepage-headline-tiny {
  @extend %homepage-teaser-tiny-headline;
}

.homepage-paragraph {
  @extend %homepage-teaser-paragraph;
}

.homepage-strong {
  @extend %homepage-teaser-strong;
}

.home-indent-left {
  @include minMd {
    padding-left: 102px;
  }
}

.portrait {
  text-decoration: underline;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.homepage-headline {
  color: $white;
  font-family: 'Poppins Light';
  font-size: 32px;
  line-height: 48px;
  padding-bottom: $spacing * 1;

  @include minSm {
      font-size: 44px;
      line-height: 58px;
  }

  &__separator {
      border-bottom: 1px solid rgba($white, .1);
  }
}

.headline-incognito {
  @include themify($themes) {
    color: themed('textSecondary');
  }
}

.paragraph-incognito {
  @extend %standard-typographic-format;

  &--all-caps {
      text-transform: uppercase;
  }

  a {
      color: inherit;
      text-decoration: underline;
  }
}


.ordered-list-incognito {
  @extend %standard-typographic-format;
  margin-bottom: $spacing * 5;
  padding-left: $spacing * 5;
}

.paragraph {
  @extend %standard-typographic-format;
}

.typographic-list {
  @extend %standard-typographic-format;
  list-style-type: unset;
  margin-bottom: $spacing * 5;

  padding-left: $spacing * 6;

  &--ordered {
      list-style-type: decimal;
  }

  &--no-indention.typographic-list--ordered {
      counter-reset: section;
      list-style-position: inside;
      list-style-type: none;
      padding-left: 0;
  }

  .typographic-list {
      margin-bottom: 0;
  }

  &__headline {
      @extend %standard-typographic-format;
      display: flex;
      font-style: italic;
      line-height: 1.5;
      margin: ($spacing * 8) 0 ($spacing * 2) ($spacing * 6);

      &::before {
          counter-increment: section;
          content: counters(section, ".") ".";
          display: inline-block;
          font-style: italic;
          margin-right: $spacing;
      }
  }
}

.strong {
  font-family: "Poppins Semi Bold";
}

.blockquote {
  margin: 0 ($spacing * 4);

  @include minSm {
      margin-left: $spacing * 8;
      margin-right: $spacing * 8;
  }

  & > .paragraph:first-of-type {
      margin-top: 0;
  }
}

.copytext-link {
  color: $cobalt-100;
  text-decoration: underline;

  &:hover {
      text-decoration: none;
  }
}

.copytext-subheadline {
  margin-bottom: 0;
}

/**
 * Todo: find out if this is the right place to
 * reset basic element styles.
 */
abbr {
  text-decoration: none;
}