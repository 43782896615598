/**
 * # Navbar - Main Navigation
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.navbar {
    display: flex;
    justify-content: space-between;
    margin: 0 ($spacing * 4);
    padding: ($spacing * 4) 0 15px;
    position: relative;
    max-width: $screen-max-md-aside;
    width: calc(100% - ($spacing * 8));
    z-index: 6;

    @include minSm {
        margin: 0 auto;
        padding-top: $spacing * 8;
    }

    &--interior {
        @include themify($themes) {
            border-bottom: 1px solid themed('strokeSecondary');
        }
    }

    &__slogan {
        color: rgba($black, .5);
        font-family: "Poppins Light", Helvetica, Arial;
        font-size: 16px;
        margin: $spacing 0 0;

        @include minSm {
            margin-top: 0;
        }

        .dark-theme & {
            color: rgba($white, .5);
        }
    }

    &__slogan-first-part {
        white-space: nowrap;
    }

    &__logo {
        transform-origin: left;

        @include maxSm {
            max-width: 120px;
            height: auto;
        }
    }
}