@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';
@import '../../Layout.scss';

.header {
  position: relative;
  z-index: $z-index-header-default;

  &.fixed {
    margin-bottom: $header-height-mobile;

    @include minSm {
      margin-bottom: $header-height-narrow;
    }

    .search-input {
      .input {
        height: auto;
        padding-top: $spacing;
        padding-bottom: $spacing;
        font-size: 16px;
      }

      &__clear {
        top: 3px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &__button {
        top: 2px;
        height: 24px;
        width: 24px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    form.search-input {
      &--mobile-active.search-input {
        .input {
          padding: 11px 20px 11px 42px;
        }

        .search-input {
          &__clear {
            top: 7px;
            right: 71px;

            svg {
              width: 24px;
              height: 24px;
            }

            @media (max-width: 575px) {
              right: 15px;
            }
          }

          &__button {
            svg {
              width: 20px;
              height: 20px;
            }

            &--search {
              top: 9px;
              right: 15px;

              svg {
                width: 21px;
                height: 21px;
              }
            }

            &--back {
              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }

  @include maxMd() {
    &__addon-button.browser-cta-button {
      display: none;
    }
  }

  &__inner {
    position: relative;
    z-index: 2;

    @include minSm {
      max-width: $inner-body-breakpoint;
      padding-top: $spacing * 6;
    }

    @include minMd {
      max-width: $inner-body-breakpoint-big;
      padding: ($spacing * 6) 38px 0;
    }

    .fixed & {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      animation: header-scroll .1s linear;
      box-shadow: 0 2px 10px rgba($black, 0.15);
      padding-bottom: $spacing * 4;

      @include themify($themes) {
        background-color: themed('backgroundSecondary');
      }

      @include minSm {
        max-width: 100%;
        padding: $spacing * 3;
      }

      .dark-theme & {
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.7);
      }
    }
  }

  &__main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include minSm {
      flex-wrap: nowrap;
      padding-bottom: $spacing * 3;
    }

    .fixed & {
      @include minSm {
        margin: 0 auto;
        max-width: 680px;
        padding-bottom: 0;
      }

      @include minMd {
        max-width: 1200px;
      }
    }
  }

  &__search-input-wrapper {
    position: relative;
    flex: 0 1 100%;
    height: 46px;

    @include minSm {
      margin-left: $spacing * 6;
    }

    @include minMd {
      max-width: 540px;
      margin-right: auto;
    }

    @include minLg {
      max-width: 724px;
      margin-left: -$spacing * 5;
    }

    .fixed & {
      height: $spacing * 8;

      @include minSm {
        margin-left: 42px;
      }

      @include minLg {
        margin-left: -20px;
      }
    }

    .input {
      padding-left: 20px;
    }
  }

  .search-input {
    @include minMd {
      margin-top: -2px;
    }

    &--open {
      @include minSm {
        width: 484px;
      }

      @include minMd {
        width: 560px;
      }

      @include minLg {
        width: 744px;
      }
    }
  }

  &__handlers {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 700px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    margin-top: $spacing * 2;
    margin-left: -$spacing * 4;
    margin-right: -$spacing * 4;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 340px) {
      width: auto;
    }

    @media (min-width: 480px) {
      overflow-x: visible;
      flex-wrap: wrap;
    }

    @include minSm {
      flex-wrap: nowrap;
      margin-top: 0;
    }

    .fixed & {
      display: none;
    }
  }

  &__menu {
    @include minSm {
      width: auto;
      order: 1;
      margin-bottom: 0;
    }
  }

  &__logo-link {
    @media (min-width: 1632px){
      position: absolute;
      left: -182px;

      .fixed & {
        left: -172px;
      }
    }
  }

  &__logo {
    display: inline-block;
    width: 111px;
    height: 27px;
    margin: ($spacing * 4) 0;

    .fixed & {
      width: 115px;
      height: 33px;
    }

    @include minSm {
      width: 132px;
      height: 32px;
      margin: 0;

      .fixed & {
        width: 122px;
        height: 30px;
      }
    }
  }

  &__tabs {
    display: flex;
    padding: 0 $spacing;
    margin: 0;
    position: relative;
  }

  &__tabs-item {
    margin: 0 ($spacing * 3);
  }

  &__tab-button {
    display: flex;
    align-items: center;
    border: none;
    border-bottom: 2px solid transparent;
    padding: ($spacing * 2.75) 2px 11px 0;
    position: relative;
    font-size: 14px;
    margin: 0;

    @include minSm {
      padding-right: $spacing;
    }

    @include themify($themes) {
      color: themed('textPrimary');
    }

    /**
      * - Active state bold text without layout
      *   shift on click
      */
    &::after {
      content: attr(data-title);
      display: block;
      left: 0;
      position: absolute;
      top: $spacing * 3;
      transition: 0.25s color ease-out;

      @include minSm {
        left: 22px;
      }
    }

    @supports (-webkit-background-clip: text) or (background-clip: text) {
      &:hover::after,
      &--active::after {
        color: transparent;

        @include themify($themes) {
          color: themed('accentPrimary');
        }
      }

      &--active::after {
        font-weight: bold;
      }
    }

    &--active {
      position: relative;
      color: $blue-secondary;

      /**
       * - Active state underlining
       */
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        height: 2px;
        width: 100%;
        display: block;

        @include themify($themes) {
          background: themed('accentPrimary');
        }
      }
    }
  }

  &__tab-icon {
    transition: 0.25s all ease-out;
    margin-right: $spacing * 2;
    display: none;

    @include themify($themes) {
      color: themed('iconPrimary');
    }

    @include minSm {
      display: inline-block;
      outline: none;
    }

    .header__tab-button:active & {
      @include themify($themes) {
        color: themed('accentPrimary');
      }
    }

    .header__tab-button:hover &,
    .header__tab-button--active & {
      @include themify($themes) {
        color: themed('accentPrimary');
      }
    }
  }

  &__tab-label {
    color: transparent;
  }

  &__main-filters {
    display: flex;
    margin: $spacing ($spacing) 0 0;
    padding: 0;
  }

  &__main-filters-item {
    &:nth-of-type(n + 2) {
      margin-left: $spacing * 2;
    }
  }

  &__filters-button {
    width: auto;
    padding: 7px;
    margin-top: 1px;
    border: 1px solid transparent;
    font-size: 14px;
    line-height: 1;
    border-radius: $border-radius-s;

    .fixed & {
      border: 1px solid $lavender;

      .dark-theme & {
        border: 1px solid $asphalt-grey;
      }
    }

    @include themify($themes) {
      color: themed('textPrimary');
    }

    @include minSm {
      margin-bottom: 0;
    }
  }

  &__options {
    margin: 0 auto;
    width: 100%;

    @include themify($themes) {
      border-top: 1px solid themed('strokePrimary');
    }
  }

  &__options-inner {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: $spacing * 11;
    overflow-x: auto;
    // Prevents iPhone from bouncing on y axis when swiping.
    overflow-y: hidden;
    width: 100%;
    row-gap: $spacing * 2;
    scroll-snap-type: x mandatory;
    padding: 0 $spacing;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }

    @include minSm {
      margin: 0 auto;
      max-width: $inner-body-breakpoint;
    }

    @include minMd {
      max-width: $inner-body-breakpoint-big;
    }

    @include minMd {
      max-width: $inner-body-breakpoint-big;
      padding: ($spacing * 2) 25px 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .button-wrapper {
      position: relative;
      color: $onyx;
    }

    .select-with-search__button {
      width: auto;
    }
  }

  &__options-select {
    scroll-snap-align: start;

    &--filter {
      .react-select__option--is-selected {
        position: relative;

        &::after {
          @extend %checkmark-element;
        }
      }
    }

    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    &--reset {
      padding-left: $spacing;
    }

    .react-select-container {
      padding-right: $spacing;
    }
  }

  &__options-button {
    font-size: 14px;
    border-radius: $border-radius-l;
    padding: 7px ($spacing * 3);

    @include themify($themes) {
      background-color: themed('backgroundPrimary');
      color: themed('textTertiary');
      border: 1px solid themed('strokePrimary');
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('backgroundPrimary-hover');
      }
    }

    &:focus-visible {
      @include themify($themes) {
        outline: 2px solid themed('backgroundPrimaryFocus');
      }
    }

    &:active {
      @include themify($themes) {
        background-color: themed('backgroundPrimaryPressed');
      }
    }
  }

  &__options-popover {
    min-width: 168px;
  }

  &__countries {
    .select-with-search {
      background: none;
      border: none;

      &__select {
        top: 35px;
        width: 240px;
        max-width: 240px;
        height: auto;
        right: 0;
      }

      &__menu-list {
        height: 240px;
      }

      &__button {
        padding: 7px;
        min-width: initial;
      }
    }

    .react-select {
      &__menu-list {
        height: 240px;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &--mobile {
      position: relative;

      @media (max-width: 1024px) {
        .select-with-search {
          &__select {
            @media (max-width: 575px) {
              width: 240px;
            }
          }

          &__button {
            justify-content: flex-start;
          }
        }
      }

      @include minSm {
        display: none;
      }
    }
  }

  .countries-option {
    align-items: center;
    display: flex;
    line-height: 1;

    &__name {
      font-size: 14px;
      font-style: normal;
      line-height: 1;
      flex-grow: 1;
      padding-top: 2px;
      padding-bottom: 2px;
      max-width: 178px;
      @extend %ellipsis;

      @include maxSm {
        max-width: 147px;
      }

      @include themify($themes) {
        color: themed('textPrimary');
      }
    }

    &__selected-item {
      display: flex;
      font-weight: bold;
      max-width: 206px;

      @include maxSm {
        max-width: 175px;
      }

      .dark-theme & {
        .countries-option__name {
          color: $dark-mode-description;
        }
      }

      &::after {
        @extend %checkmark-element;
      }
    }
  }

  .menu {
    &__body {
      overflow: auto;

      @include minSm {
        top: 2px;
        right: -3px;
      }
    }

    .react-select {
      &__menu {
        .dark-theme & {
          box-shadow: 0 7px 25px rgba(0, 0, 0, 0.06);
        }

        @include themify($themes) {
          background: themed('settingInput');
        }
      }

      &__option {
        &--is-focused,
        &:hover {
          .dark-theme & {
            background-color: $dark;
          }
        }
      }
    }

    &__row-border {
      @include themify($themes) {
        background: themed('modalPrimary');
        border: 1px solid themed('modalStrokePrimary');
      }
    }

    &__theme-button {
      .dark-theme & {
        background: $button-color-dark-theme;
      }

      &--active {
        @include themify($themes) {
          background: themed('modalPrimary');
        }
      }
    }
  }
}

@keyframes header-scroll {
  0% {
    margin-top: -56px;
  }

  100% {
    margin-top: 0;
  }
}
